<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Custom OJT/MA Export</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-activity-type" label="Training Type:">
                                    <b-input-group size="sm">
                                        <b-select id="search-activity-type" v-model="selectedActivityType" :options="activityTypeOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="selectedActivityType = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-display-id" label="Display ID:">
                                    <b-input-group>
                                        <b-input id="search-display-id"
                                                 size="sm"
                                                 placeholder="Display ID"
                                                 v-model="displayIdFilter" />
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="displayIdFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-created-by-lastname" label="Created By:">
                                    <b-input-group>
                                        <b-input id="search-created-by-lastname"
                                                 size="sm"
                                                 placeholder="Last Name"
                                                 v-model="createdByLastnameFilter" />
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="createdByLastnameFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-contractor-name" label="Contractor Name:">
                                    <b-input-group>
                                        <b-input id="search-contractor-name"
                                                 size="sm"
                                                 placeholder="Contractor Name"
                                                 v-model="contractorNameFilter" />
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="contractorNameFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-contractor-type" label="Contractor Type:">
                                    <b-input-group>
                                        <b-select id="search-contractor-type"
                                                  size="sm"
                                                  :options="contractorTypeOptions"
                                                  v-model="contractorTypeFilter">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="contractorTypeFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="created-date-range" label="Created:">
                                    <picker-of-dates id="created-date-range"
                                                     v-model="createdDateRange"
                                                     format="M/d/yyyy"
                                                     clearable range/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="approved-date-range" label="Approved:">
                                    <picker-of-dates id="approved-date-range"
                                                     v-model="approvedDateRange"
                                                     format="M/d/yyyy"
                                                     clearable range/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="revision-date-range" label="Revision:">
                                    <picker-of-dates id="revision-date-range"
                                                     v-model="revisionDateRange"
                                                     format="M/d/yyyy"
                                                     clearable range/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                            <b-button variant="primary" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import download from '@/util/downloader';
import _ from 'underscore';
import store from '@/store/store';
import PickerOfDates from '@/components/shared/PickerOfDates';
import { date } from '@/util/formatters';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";


@Component({
    components: {
        PickerOfDates
    }
})
export default class CustomOjtMaExport extends Vue {

    selectedActivityType = null;
    displayIdFilter = null;
    createdByLastnameFilter = null;
    contractorNameFilter = null;
    contractorTypeFilter = null;
    createdDateRange = [null,null];
    approvedDateRange = [null,null];
    revisionDateRange = [null,null];


    get activityTypeOptions() {
        return [{value:"OJT",text:"OJT"},{value:"MA",text:"MA"}]
    }

    get contractorTypeOptions() {
        const types = this.$store.getters['organizations/getContractorTypes'];

        return _.map(types, (t) => {
            return {
                value: t,
                text: t.type
            };
        });
    }


    formatDateTimeRange(dateRange) {
        // console.log("> formatDateRange");
        dateRange[0] = dateRange[0] ? date(dateRange[0], date.DEFAULT_DATE_FORMAT) + "T00:00:00.000Z" : null;
        dateRange[1] = dateRange[1] ? date(dateRange[1], date.DEFAULT_DATE_FORMAT) + "T23:59:59.999Z" : null;
        return dateRange;
    }


    clearFilters() {
        this.selectedActivityType = null;
        this.displayIdFilter = null;
        this.createdByLastnameFilter = null;
        this.contractorNameFilter = null;
        this.contractorTypeFilter = null;
        this.createdDateRange = [null,null];
        this.approvedDateRange = [null,null];
        this.revisionDateRange = [null,null];
    }

    async getCsv() {
        try {
            await download('POST', '/api/v1/export/custom-ojt-ma', JSON.stringify({
                activityType: this.selectedActivityType,
                displayId: this.displayIdFilter,
                createdByLastName: this.createdByLastnameFilter,
                contractorName: this.contractorNameFilter,
                contractorType: this.contractorTypeFilter,
                createdDateTimeRange: this.formatDateTimeRange(this.createdDateRange),
                approvedDateTimeRange: this.formatDateTimeRange(this.approvedDateRange),
                revisionDateTimeRange: this.formatDateTimeRange(this.revisionDateRange)
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async beforeRouteEnter(to, from, next) {
        const deps = [
            'organizations/loadContractorTypes'
        ];
        _.each(deps, (action) => {
            store.dispatch(action)
                .catch(error => {
                    const vm = new Vue();
                    vm.$bvToast.toast(error.message, errorToastOptions);
                });
        });
        next();
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Custom OJT/MA Export', null, true));
    }
}
</script>
